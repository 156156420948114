import { useContext, useEffect } from 'react';

import Loader from '@components/Loader';
import { UserContext } from '@contexts/UserContext';
import { initializeApp } from '@firebase/app';
import AcceptInvitationScreen from '@screens/AcceptInvitation';
import DashboardProjectView from '@screens/DashboardProjectView';
import EmailVerificationScreen from '@screens/EmailVerification';
import Home from '@screens/Home';
import OnboardingLayout from '@screens/OnBoarding';
import AddMemberScreen from '@screens/OnBoarding/AddMember';
import WorkspaceCreateScreen from '@screens/OnBoarding/WorkspaceCreate';
import WorkspaceTimeModeScreen from '@screens/OnBoarding/WorkspaceTimeMode';
import RecoverPasswordScreen from '@screens/RecoverPassword';
import SignInScreen from '@screens/SignIn';
import SignUpScreen from '@screens/SignUp';
import * as Sentry from '@sentry/react';
import Modal from 'react-modal';
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { ROUTES } from '@/types/routes';

import AuthRedirectScreen from '@/screens/AuthRedirect';
import ResetPasswordScreen from '@/screens/AuthRedirect/ResetPassword';
import ValidateEmailAccount from '@/screens/AuthRedirect/ValidateEmail';
import VerifyAndChangeEmailScreen from '@/screens/AuthRedirect/VerifyAndchangeEmail';
import DashboardResourceView from '@/screens/DashboardResourceView';
import Error404 from '@/screens/ErrorScreen/404';

import './i18n';

import { PrivateRoute } from './PrivateRoute';


/**
 * Add firebase config here
 */

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

/**
 * init firebase app
 */
initializeApp(firebaseConfig);

if (!import.meta.env.VITEST) {
  Modal.setAppElement('#root');
}

if (import.meta.env.MODE !== 'development') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DNS,
    environment: import.meta.env.VITE_SENTRY_ENVIROMENT,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/mz-plann-staging\.herokuapp\.com\/api/,
      /^https:\/\/backendd.hellotime\.team\/api/,
    ],
    integrations: [
      // eslint-disable-next-line
      Sentry.browserTracingIntegration(),

      // eslint-disable-next-line
      Sentry.replayIntegration(),
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

export default function App() {
  const { isLoading } = useContext(UserContext);

  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

  const router = sentryCreateBrowserRouter([
    {
      path: '*',
      element: <Error404 />,
    },
    {
      path: '/error',
      element: <Error404 />,
    },
    {
      path: ROUTES.SIGN_IN,
      element: <SignInScreen />,
    },
    {
      path: ROUTES.SIGN_UP,
      element: <SignUpScreen />,
    },
    {
      path: ROUTES.EMAIL_VERIFICATION,
      element: <EmailVerificationScreen />,
    },
    {
      path: ROUTES.PASSWORD_RECOVER,
      element: <RecoverPasswordScreen />,
    },
    {
      path: ROUTES.PASSWORD_RESET,
      element: <ResetPasswordScreen />,
    },
    {
      path: ROUTES.AUTH,
      element: <AuthRedirectScreen />,
    },
    {
      path: ROUTES.VALIDATE_EMAIL,
      element: <ValidateEmailAccount />,
    },
    {
      path: ROUTES.VALIDATE_AND_CHANGE_EMAIL,
      element: <VerifyAndChangeEmailScreen />,
    },
    {
      path: ROUTES.RECOVER_EMAIL,
      element: <VerifyAndChangeEmailScreen />,
    },
    {
      path: ROUTES.ON_BOARDING,
      element: (
        <PrivateRoute>
          <OnboardingLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: '',
          element: <WorkspaceCreateScreen />,
        },
        {
          path: ROUTES.ADD_MEMBER,
          element: <AddMemberScreen />,
        },
        {
          path: ROUTES.WORKSPACE_SET_TIME_MODE,
          element: <WorkspaceTimeModeScreen />,
        },
      ],
    },
    {
      path: ROUTES.ACCEPT_INVITATION,
      element: (
        <PrivateRoute>
          <AcceptInvitationScreen />
        </PrivateRoute>
      ),
    },
    {
      element: (
        <PrivateRoute>
          <Home />
        </PrivateRoute>
      ),
      children: [
        {
          path: ROUTES.ROOT,
          element: <DashboardResourceView />,
        },
        {
          path: ROUTES.DASHBOARD,
          exact: true,
          element: <DashboardResourceView />,
        },
        {
          path: ROUTES.PROJECT,
          exact: true,
          element: <DashboardProjectView />,
        },
      ],
    },
  ]);

  return isLoading ? <Loader /> : <RouterProvider router={router} />;
}
