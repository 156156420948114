/* eslint-disable import/named */
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Button from '@components/Button';
import Toolbar from '@components/Toolbar';
import {
  Active,
  AutoScrollActivator,
  DataRef,
  DndContext,
  DragOverEvent,
  DragOverlay,
  Over,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import { arrayMove } from '@dnd-kit/sortable';
import * as Tooltip from '@radix-ui/react-tooltip';
import {
  IconArrowsDiagonal,
  IconArrowsDiagonalMinimize2,
  IconLineHeight,
  IconPlus,
} from '@tabler/icons-react';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import type { VirtualItem } from '@tanstack/react-virtual';
import useThrottledCallback from 'beautiful-react-hooks/useThrottledCallback';
import classNames from 'classnames';
import {
  add,
  differenceInCalendarDays,
  format,
  getDay,
  getWeekOfMonth,
  getWeeksInMonth,
  isBefore,
  isSameWeek,
  setDefaultOptions,
  startOfMonth,
  startOfToday,
  startOfWeek,
} from 'date-fns';
import { t } from 'i18next';
import { cloneDeep, find, findIndex, flatMap, forEach, isNil } from 'lodash';
import { ClipLoader } from 'react-spinners';

import { UIContext } from '@/contexts/UIContext';
import { UserContext } from '@/contexts/UserContext';
import { useIntersectionObserver } from '@/hooks/utils/useIntersectionObserver';
import useProjectCountByStatus from '@/hooks/workspace/projects/useProjectCountByStatus';
import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '@/hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import useReorderProjectsList from '@/hooks/workspace/projects/useReorderProjects';
import {
  createChunks,
  pointerAndClosestCorners,
  weeksToPixels,
} from '@/services/helpers';
import {
  PAGE_SIZE,
  ProjectStatusEnumOrder,
  orderProjectsByStatus,
} from '@/services/helpers/timelines/projects';
import { PROJECT_STATUS, WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { TPagedQuery } from '@/types/generic';
import { ROUTES } from '@/types/routes';
import {
  TProjectListWithResources,
  TProjectListWithResourcesStatus,
  TResourceItemList,
} from '@/types/timeline';

import ModalAddNewProject from '@/components/Modals/ModalAddNewProject';
import ModalCompletedProjects from '@/components/Modals/ModalCompletedProjects';
import SidebarResizer from '@/components/SidebarResizer';
import SkeletonTimelineHeader from '@/components/Skeletons/SkeletonTimelineHeader';
import {
  TimelineProjectsContext,
  WEEKS_TO_ADD,
  WORKING_DAYS_IN_A_WEEK,
} from '@/components/Timelines/TimelineProjects/context';

import AccordionRowWrapper from './AccordionRowWrapper';
import ProjectStatusWrapper from './ProjectStatusWrapper';
import styles from './styles.module.css';
import ManageAutomationsIcon from '../common/ManageAutomationsIcon';

type SortableDataRef = DataRef<{
  sortable: {
    containerId: PROJECT_STATUS;
    index: number;
    items: UniqueIdentifier[];
  };
}>;

type DragOverEventSortable = DragOverEvent & {
  active: Active & { data: SortableDataRef };
  over: Over & { data: SortableDataRef };
};

const findContainerStatus = (
  dataContainer: DataRef<{ sortable: { containerId: PROJECT_STATUS } }>,
): PROJECT_STATUS | undefined => dataContainer.current?.sortable?.containerId;

export default function TimelineProjects() {
  setDefaultOptions({ weekStartsOn: 1 });

  const todayRef = useRef<HTMLDivElement>(null);
  const previousScrollInPx = useRef(0);
  const isAddingWeeks = useRef(false);

  const {
    resetViewOnToday,
    setResetViewOnToday,
    updateTimeInterval,
    timeInterval,
    weeks,
    virtualizer,
    ref: containerRef,
    sidebarRef,
    isLoading,
    weekSizeInPx,
    setIsSorting,
    onExpandOrCompressAllFn,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
  } = useContext(TimelineProjectsContext);

  const { layoutIsExpanded, onChangeLayout } = useContext(UIContext);

  const { workspaceId, workspace } = useContext(UserContext);

  const [completedProjectsModalOpen, setCompletedProjectsModalOpen] =
    useState(false);

  const fetchOnScroll = useCallback(
    (entry: IntersectionObserverEntry[]) => {
      if (entry?.[0]?.isIntersecting) fetchNextPage?.();
    },
    [fetchNextPage],
  );
  const { setTarget } = useIntersectionObserver(fetchOnScroll);

  const today = startOfToday();
  const currentWeek = startOfWeek(today);
  const weekday = getDay(today) - 1 >= 0 ? getDay(today) - 1 : 6; // We want Monday to equal 0 and Sunday to be 6
  const roundedWeekday = Math.min(weekday, WORKING_DAYS_IN_A_WEEK); // Exclude weekends
  const { mutate: reorderMutation } = useReorderProjectsList();

  const { data: completedProjects, isLoading: completedIsLoading } =
    useProjectCountByStatus({
      status: PROJECT_STATUS.COMPLETED,
      enabled: !isFetching && !hasNextPage && !!workspaceId,
    });

  // add weeks on scroll in both directions
  const handleAddWeekOnScrollFn = useThrottledCallback(
    () => {
      if (containerRef.current && !isAddingWeeks.current && !resetViewOnToday) {
        // get all the values needed to calculate the scroll
        const scrollWidth = virtualizer?.getTotalSize();
        const scrollLeft = virtualizer.scrollOffset ?? 0;
        const direction = virtualizer.scrollDirection;
        const clientWidth = virtualizer.scrollElement?.clientWidth ?? 0;

        // reset the previousScrollInPx to prevent wrong scroll position when scrolling back
        if (direction === 'forward') previousScrollInPx.current = 0;

        // check if the scroll is at the end or the beginning
        if (
          direction === 'forward' &&
          scrollWidth - scrollLeft - clientWidth < clientWidth / 4
        ) {
          isAddingWeeks.current = true;
          updateTimeInterval('after');
        } else if (direction === 'backward' && scrollLeft < clientWidth / 4) {
          isAddingWeeks.current = true;
          // add weeks to add to the previous scroll position to prevent wrong scroll position
          // and set the value to previousScrollInPx that will be used in the useEffect
          previousScrollInPx.current = scrollLeft + WEEKS_TO_ADD * weekSizeInPx;
          updateTimeInterval('before');
        }
      }
    },
    [updateTimeInterval],
    100,
  );

  useEffect(() => {
    if (todayRef.current && resetViewOnToday) {
      todayRef.current.scrollIntoView({
        inline: 'center',
      });
      setResetViewOnToday(false);
    }
  }, [resetViewOnToday, setResetViewOnToday]);

  useEffect(() => {
    if (
      containerRef.current &&
      previousScrollInPx.current !== 0 &&
      isAddingWeeks.current
    ) {
      virtualizer.scrollToOffset(previousScrollInPx.current);
    }
    isAddingWeeks.current = false;
  }, [containerRef, timeInterval, virtualizer]);

  const queryClient = useQueryClient();
  const renderWeekHeader = useCallback(
    (virtualWeek: VirtualItem) => {
      const startDate = weeks[virtualWeek.index];
      const endDate = add(startDate, { days: WORKING_DAYS_IN_A_WEEK - 1 });
      const firstDayOfMonth = startOfMonth(startDate);
      const weekOfMonth = getWeekOfMonth(startDate);
      const weeksInMonth =
        getWeeksInMonth(startDate) -
        (weekOfMonth === 2 && isBefore(firstDayOfMonth, startDate) ? 1 : 0);
      const isFirstWeekOfMonth =
        weekOfMonth === 1 ||
        (weekOfMonth === 2 &&
          differenceInCalendarDays(startDate, firstDayOfMonth) < 7);

      return (
        <Tooltip.Provider key={virtualWeek.key} delayDuration={0}>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <div
                className={classNames(styles.weekHeader, {
                  [styles.firstWeekHeader]: isFirstWeekOfMonth,
                })}
                style={{
                  cursor: 'pointer',
                  width: `${virtualWeek.size}px`,
                  transform: `translateX(${virtualWeek.start}px) `,
                }}
              >
                {isFirstWeekOfMonth && (
                  <span
                    className={styles.monthLabel}
                    style={{ width: weeksInMonth * weekSizeInPx }}
                  >
                    {format(startDate, 'MMMM y')}
                  </span>
                )}
                <span
                  className={classNames(styles.weekLabel, {
                    [styles.currentWeek]: isSameWeek(currentWeek, startDate),
                  })}
                >
                  <span>{format(startDate, 'd')}</span>
                  <span>{format(startDate, 'MMM')}</span>
                </span>
              </div>
            </Tooltip.Trigger>
            <Tooltip.Portal
              container={document.getElementById('radix-tooltip-portal')}
            >
              <Tooltip.Content className="TooltipContent" sideOffset={-12}>
                {`${format(startDate, 'E, dd MMM')} - ${format(
                  endDate,
                  'E, dd MMM',
                )}`}
                <Tooltip.Arrow className="TooltipArrow" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      );
    },
    [currentWeek, weekSizeInPx, weeks],
  );

  const renderWeek = useCallback(
    (virtualWeek: VirtualItem) => {
      const startDate = weeks[virtualWeek.index];
      const firstDayOfMonth = startOfMonth(startDate);
      const weekOfMonth = getWeekOfMonth(startDate);
      const isFirstWeekOfMonth =
        weekOfMonth === 1 ||
        (weekOfMonth === 2 &&
          differenceInCalendarDays(startDate, firstDayOfMonth) < 7);

      return (
        <div
          key={virtualWeek.key}
          className={classNames(styles.week, {
            [styles.firstWeek]: isFirstWeekOfMonth,
          })}
          style={{
            width: `${virtualWeek.size}px`,
            transform: `translateX(${virtualWeek.start}px) `,
          }}
        />
      );
    },
    [weeks],
  );

  const [activeProjectSortingId, setActiveProjectSortingId] =
    useState<UniqueIdentifier | null>(null);

  const [startActiveStatus, setStartActiveStatus] = useState<
    PROJECT_STATUS | undefined
  >(undefined);

  const [currentData, setCurrentData] = useState<
    | InfiniteData<TPagedQuery<TProjectListWithResources<TResourceItemList>>>
    | undefined
  >();

  const onDragStartFn = useCallback(
    ({ active }: DragOverEventSortable) => {
      setActiveProjectSortingId(active?.id);
      setIsSorting(true);
      setStartActiveStatus(findContainerStatus(active.data));
      setCurrentData(
        cloneDeep(
          queryClient.getQueryData([
            PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY,
            workspaceId,
          ]),
        ),
      );
    },
    [queryClient, setIsSorting, workspaceId],
  );

  const onDragEndFn = useCallback(
    ({ over, active }: DragOverEventSortable) => {
      setActiveProjectSortingId(null);
      setIsSorting(false);
      if (
        findContainerStatus(active.data) === startActiveStatus &&
        active.id === over?.id
      )
        return;
      const oldStatusIndex =
        data?.pages?.[0].results.findIndex((p) =>
          p.projects.some((pr) => pr.id === active.id),
        ) ?? -1;

      const newStatusIndex =
        data?.pages?.[0].results.findIndex((p) =>
          p.projects.some((pr) => pr.id === over?.id),
        ) ?? -1;
      if (oldStatusIndex > -1 && newStatusIndex > -1) {
        const resPrj = data?.pages[0].results?.[oldStatusIndex].projects ?? [];
        const oldIndex = resPrj.findIndex((p) => p.id === active.id);
        if (oldStatusIndex === newStatusIndex) {
          const newIndex = resPrj.findIndex((p) => p.id === over?.id);
          data!.pages[0].results![oldStatusIndex].projects = arrayMove(
            resPrj,
            oldIndex,
            newIndex,
          );
        } else {
          const newResPrj = data?.pages[0].results?.[newStatusIndex].projects;
          const newIndex = newResPrj?.findIndex((p) => p.id === over?.id) ?? -1;
          if (newIndex > -1)
            newResPrj?.splice(newIndex, 0, resPrj.splice(oldIndex, 1)?.[0]);
        }
        if (!isNil(over?.data.current?.sortable.index)) {
          reorderMutation({
            projectId: activeProjectSortingId as string,
            newOrder: over.data.current.sortable.index + 1,
            newStatus: over.data.current.sortable.containerId,
            oldStatus: active.data.current?.sortable.containerId,
          });
        }
      }
    },
    [
      setIsSorting,
      startActiveStatus,
      data,
      reorderMutation,
      activeProjectSortingId,
    ],
  );

  const onDragCancel = useCallback(() => {
    setActiveProjectSortingId(null);
    setIsSorting(false);
    if (currentData) {
      queryClient.setQueryData(
        [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY, workspaceId],
        currentData,
      );
    }
  }, [currentData, queryClient, setIsSorting, workspaceId]);

  const onDragOver = ({ active, over }: DragOverEventSortable) => {
    const activeStatus = findContainerStatus(active.data);
    const key = [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY, workspaceId];
    const overStatus: PROJECT_STATUS | undefined = findContainerStatus(
      over?.data,
    );
    const queryData = cloneDeep(
      queryClient.getQueryData<
        InfiniteData<TPagedQuery<TProjectListWithResources<TResourceItemList>>>
      >(key),
    );
    if (!queryData || !(activeStatus && overStatus)) return;
    const currentPrjId = active.id as string;
    const overPrjId = over.id as string;
    const allResults = orderProjectsByStatus(
      queryData?.pages?.flatMap((page) => page.results),
    );
    if (!allResults) return;
    if (activeStatus !== overStatus) {
      const direction: number = Number(
        ProjectStatusEnumOrder.indexOf(activeStatus) >
          ProjectStatusEnumOrder.indexOf(overStatus),
      );

      allResults.splice(
        findIndex(allResults, { id: overPrjId }) + direction,
        0,
        {
          ...allResults.splice(
            findIndex(allResults, { id: currentPrjId }),
            1,
          )[0],
          status: overStatus,
        },
      );
      forEach(
        createChunks(allResults, PAGE_SIZE),
        (res, idx) => (queryData.pages[idx].results = res),
      );
      queryClient.setQueryData(key, queryData);
    }
  };

  const canShowEmpty = useMemo(
    () => data?.pages?.length,
    [data?.pages?.length],
  );

  const currentDraggedProject = useMemo(
    () =>
      find(
        flatMap(data?.pages?.[0].results, (r) => r.projects),
        {
          id: activeProjectSortingId,
        },
      ) as TProjectListWithResources<TResourceItemList>,
    [activeProjectSortingId, data?.pages],
  );

  const renderPagedTimelineWrapRow = useCallback(() => {
    let currentPage:
      | TPagedQuery<TProjectListWithResourcesStatus<TResourceItemList>>
      | undefined;
    if (!canShowEmpty || !(currentPage = data?.pages?.[0])) return undefined;
    const elems = currentPage.results.map(({ status, projects }) => (
      <ProjectStatusWrapper
        key={`${status}`}
        status={status}
        projects={projects}
        activeSortingId={activeProjectSortingId}
      />
    ));

    if (hasNextPage && !(isLoading || isFetching)) {
      elems?.push(
        <div
          key={`loader_${currentPage.nextPage ?? -1}`}
          id={`loader_${currentPage.nextPage ?? -1}`}
          ref={setTarget}
          className={classNames(styles.pagination, {
            [styles.layoutExpanded]: layoutIsExpanded,
          })}
        ></div>,
      );
    }
    return elems;
  }, [
    activeProjectSortingId,
    canShowEmpty,
    data?.pages,
    hasNextPage,
    isFetching,
    isLoading,
    layoutIsExpanded,
    setTarget,
  ]);

  const [addProjectModalOpen, setAddProjectModalOpen] = useState(false);
  const onCloseAddProjectModal = useCallback(
    () => setAddProjectModalOpen(false),
    [],
  );

  const onOpenAddProjectModal = useCallback(
    () => setAddProjectModalOpen(true),
    [],
  );

  const totalCompletedProjects: number = completedProjects?.totalCount ?? 0;

  return (
    <div
      ref={containerRef}
      className={styles.wrapper}
      onScroll={handleAddWeekOnScrollFn}
    >
      <Tooltip.Provider delayDuration={0}>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <div className={styles.floatingButton}>
              <Toolbar>
                <Button
                  icon={
                    layoutIsExpanded
                      ? IconArrowsDiagonalMinimize2
                      : IconArrowsDiagonal
                  }
                  variant="ghost"
                  size="large"
                  onClick={onChangeLayout}
                />
              </Toolbar>
            </div>
          </Tooltip.Trigger>
          <Tooltip.Portal
            container={document.getElementById('radix-tooltip-portal')}
          >
            <Tooltip.Content className="TooltipContent" sideOffset={8}>
              {layoutIsExpanded ? t('common:compress') : t('common:expand')}
              <Tooltip.Arrow className="TooltipArrow" />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>

      <div
        className={styles.container}
        style={{ width: `${virtualizer?.getTotalSize()}px` }}
      >
        <SidebarResizer />

        <div className={styles.sidebar} ref={sidebarRef}>
          {!data?.pages?.length && !isLoading ? (
            <div className={styles.emptyState}>
              {t('timeline:emptyStateResources')}
            </div>
          ) : !data?.pages?.length && isLoading ? (
            Array.from({ length: 8 }).map((_, index) => (
              <SkeletonTimelineHeader
                isExpanded={layoutIsExpanded}
                key={index}
              />
            ))
          ) : null}
        </div>
        <div className={styles.filters}>
          <div className={styles.filtersWrapper}>
            <div className={styles.filterLeft}>
              <Button
                label={t('timeline:ctaPeopleLabel')}
                size="small"
                variant="ghost"
                link={ROUTES.DASHBOARD.replace(
                  ':workspaceId',
                  workspaceId ?? '',
                )}
              />
              <Button
                label={t('timeline:ctaProjectsLabel')}
                size="small"
                variant="ghost"
                link={ROUTES.PROJECT.replace(':workspaceId', workspaceId ?? '')}
                active
              />
            </div>
            <div className={styles.filterButtons}>
              {workspace?.permission ===
                WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE && (
                <ManageAutomationsIcon
                  autoHide={workspace?.autoHide ?? false}
                  autoSync={workspace?.autoSync ?? false}
                />
              )}
              <Tooltip.Provider delayDuration={1000}>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <span>
                      <Button
                        icon={IconLineHeight}
                        variant="ghost"
                        size="small"
                        onClick={onExpandOrCompressAllFn}
                      />
                    </span>
                  </Tooltip.Trigger>
                  <Tooltip.Portal
                    container={document.getElementById('radix-tooltip-portal')}
                  >
                    <Tooltip.Content className="TooltipContent" sideOffset={8}>
                      {t('timeline:expandAllTooltip') as string}
                      <Tooltip.Arrow className="TooltipArrow" />
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
              </Tooltip.Provider>
            </div>
          </div>
        </div>
        {workspace?.permission ===
          WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE && (
          <div className={styles.addProject}>
            <button onClick={onOpenAddProjectModal}>
              <IconPlus size={16} /> {t('timeline:addProjectCtaLabel')}
            </button>
            {addProjectModalOpen && (
              <ModalAddNewProject
                isOpen={addProjectModalOpen}
                onClose={onCloseAddProjectModal}
              />
            )}
          </div>
        )}
        <div className={styles.header}>
          {virtualizer.getVirtualItems().map(renderWeekHeader)}
        </div>
        <div className={styles.grid}>
          {virtualizer.getVirtualItems().map(renderWeek)}
          <div
            ref={todayRef}
            className={styles.today}
            style={{
              left:
                weeksToPixels(
                  currentWeek,
                  timeInterval.start,
                  false,
                  weekSizeInPx,
                ) +
                roundedWeekday * (weekSizeInPx / WORKING_DAYS_IN_A_WEEK),
            }}
          />
        </div>
        <div
          className={classNames(styles.content, {
            [styles.noPaddingBottom]:
              workspace?.permission !==
              WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE,
          })}
        >
          <div>
            <DndContext
              onDragStart={onDragStartFn}
              onDragEnd={onDragEndFn}
              onDragOver={onDragOver}
              onDragCancel={onDragCancel}
              collisionDetection={pointerAndClosestCorners}
              autoScroll={{
                activator: AutoScrollActivator.DraggableRect,
                threshold: { x: -10, y: 0.25 },
              }}
              modifiers={[
                restrictToFirstScrollableAncestor,
                restrictToVerticalAxis,
              ]}
            >
              {canShowEmpty && (
                <Fragment>
                  {renderPagedTimelineWrapRow()}
                  {isFetchingNextPage && (
                    <div className={styles.loaderContainer}>
                      <ClipLoader size={24} />
                    </div>
                  )}
                </Fragment>
              )}

              {!hasNextPage && !completedIsLoading && (
                <ProjectStatusWrapper
                  key={`${PROJECT_STATUS.COMPLETED}`}
                  status={PROJECT_STATUS.COMPLETED}
                  activeSortingId={activeProjectSortingId}
                  rightLabel={
                    totalCompletedProjects > 0 ? (
                      <Button
                        label={t(`timeline:showCompletedProjects`, {
                          count: totalCompletedProjects,
                        })}
                        variant="ghost"
                        disabled={!totalCompletedProjects}
                        onClick={() => setCompletedProjectsModalOpen(true)}
                        className={styles.rightLabel_text}
                      />
                    ) : (
                      <span className={styles.rightLabel_text}>{0}</span>
                    )
                  }
                />
              )}
              {currentDraggedProject && (
                <DragOverlay
                  key={`${currentDraggedProject.id}_drag_overlay`}
                  adjustScale={false}
                  dropAnimation={{
                    duration: 200,
                  }}
                >
                  <AccordionRowWrapper
                    key={`${currentDraggedProject.id}_overlay`}
                    isDraggableOverlay
                    project={currentDraggedProject}
                  />
                </DragOverlay>
              )}
            </DndContext>
          </div>
        </div>
      </div>
      {completedProjectsModalOpen && totalCompletedProjects && (
        <ModalCompletedProjects
          modalKey={`completedProjectsModalOpen_${completedProjectsModalOpen}`}
          totalProjects={totalCompletedProjects}
          isOpen={completedProjectsModalOpen}
          footer={undefined}
          onClose={() => setCompletedProjectsModalOpen(false)}
        />
      )}
    </div>
  );
}
