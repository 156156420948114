import { useContext } from 'react';

import type { InfiniteData } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { cloneDeep, find, flatMap, noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import {
  getProjectListWithResources,
  getTimelineProjectListWithResources,
} from '@/services/api/workspace/projects';
import { PROJECT_STATUS } from '@/types/enums';
import {
  TPagedQuery,
  TResponseError,
  TSuccessErrorHook,
} from '@/types/generic';
import { TProjectListWithResources, TResourceItemList } from '@/types/timeline';
import { handleApiCall } from '@/services/api';

export type TTimelineProjectListWithResourcesWithTimeblocksPage = InfiniteData<
  TPagedQuery<TProjectListWithResources<TResourceItemList>>
>;

export function convertProjectListWithResource(
  data?: InfiniteData<
    TPagedQuery<TProjectListWithResources<TResourceItemList>>
  >,
) {
  if (!data)
    return {
      pageParams: [],
      pages: [],
    };
  const mappedData = Array.from(
    flatMap(data.pages, (p) => p.results)
      .reduce(
        (acc, project) =>
          acc.set(
            project.status!,
            (acc.get(project.status!) ?? []).concat([project]),
          ),
        new Map<
          PROJECT_STATUS,
          TProjectListWithResources<TResourceItemList>[]
        >(),
      )
      .entries(),
  ).map(([k, v]) => ({
    status: k,
    projects: v,
  }));

  const enumOrder = Object.keys(PROJECT_STATUS);
  enumOrder.forEach((status) => {
    if (
      status !== PROJECT_STATUS.COMPLETED &&
      !mappedData.find((data) => data.status === status)
    ) {
      mappedData.push({
        status: status as PROJECT_STATUS,
        projects: [],
      });
    }
  });
  mappedData.sort((a, b) => {
    return enumOrder.indexOf(a.status) - enumOrder.indexOf(b.status);
  });
  return {
    pageParams: data.pageParams,
    pages: [
      { ...data.pages[data.pages.length - 1], results: cloneDeep(mappedData) },
    ],
  };
}

export const PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY =
  'get-projects-list-with-resources-status';
export default function useProjectListWithResourcesStatus({
  enabled = true,
  onError = noop,
}: Omit<
  TSuccessErrorHook<TTimelineProjectListWithResourcesWithTimeblocksPage>,
  'onSuccess'
> = {}) {
  const { workspaceId } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);

  return useInfiniteQuery({
    queryKey: [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY, workspaceId],
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      const [projectList, timelineList] = await handleApiCall(
        Promise.all([
          getProjectListWithResources({
            pageParam,
            workspaceId,
          }),
          getTimelineProjectListWithResources({
            workspaceId,
            page: pageParam,
          }),
        ]),
        undefined,
        (error: TResponseError) => {
          onError(error);
          addNotification({
            type: 'error',
            title: t('errors:generic.title') as string,
            description:
              error?.message ?? (t('errors:generic.description') as string),
          });
        },
      );

      const { results, ...pagedQuery } = projectList;
      results?.forEach((project) => {
        const toMergeProject = find(timelineList, { id: project?.id });

        if (toMergeProject) {
          project.resources.forEach((resource) => {
            const mergedResource = find(toMergeProject.resources, {
              id: resource.id,
            }) as TResourceItemList;
            if (mergedResource) {
              (resource as TResourceItemList).timeblocks =
                mergedResource?.timeblocks ?? [];
            }
          });
        }
      });
      return {
        ...pagedQuery,
        results: cloneDeep(results),
      };
    },
    enabled: enabled && !!workspaceId,
    select: convertProjectListWithResource,
    getNextPageParam: (lastPage) => lastPage?.nextPage && lastPage?.nextPage,
  });
}
