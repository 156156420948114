import { StrictMode } from 'react';

import './styles/index.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'react-charts';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { HotkeysProvider } from 'react-hotkeys-hook';

import { NotificationsProvider } from '@/contexts/NotificationContext';
import { UIProvider } from '@/contexts/UIContext';
import { UserProvider } from '@/contexts/UserContext';
import WebSocketProvider from '@/contexts/WebSocketContext';
import { GLOBAL_SCOPE, LOCAL_SCOPE } from '@/types/constants';

import App from './App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: Number(import.meta.env.VITE_QUERY_CACHE_TIME) },
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <HelmetProvider>
      <HotkeysProvider initiallyActiveScopes={[GLOBAL_SCOPE, LOCAL_SCOPE]}>
        <QueryClientProvider client={queryClient}>
          <UIProvider>
            <NotificationsProvider>
              <UserProvider>
                <WebSocketProvider>
                  <App />
                </WebSocketProvider>
              </UserProvider>
            </NotificationsProvider>
          </UIProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </HotkeysProvider>
    </HelmetProvider>
  </StrictMode>,
);
