import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { getResources } from '@/services/api/workspace/resources';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';
import { TResources } from '@/types/timeline';
import { handleApiCall } from '@/services/api';

export const RESOURCES_QUERY_KEY = 'get-resources';
export default function useResourcesQuery({
  enabled = true,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<TResources[]>) {
  const { workspaceId } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);

  return useQuery({
    queryKey: [RESOURCES_QUERY_KEY, workspaceId],
    queryFn: () =>
      handleApiCall(getResources({ workspaceId }), onSuccess, onError),
    enabled,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
}
